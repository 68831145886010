import {createApp} from 'vue'
import {createPinia} from 'pinia'
import mitt from 'mitt'
import {pusher} from "./pusher/index"
import PrimeVueLocaleEs from "./code/PrimeVueLocaleEs";
import * as Sentry from "@sentry/vue";
import {VERSION} from "./code/app";

const emitter = mitt();

import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import axios from "axios";
import FormError from "./components/utils/FormError.vue";
import Paginador from "./components/utils/Paginador.vue";
import FlashAlert from "./components/utils/FlashAlert.vue";
import DialogoCargando from "./components/utils/DialogoCargando.vue";
import LabelTextCounter from "./components/utils/LabelTextCounter.vue";
import permisos from './permisos'
import configuraciones from "./code/configuraciones";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = import.meta.env.VITE_SERVER_URL;
axios.defaults.headers.common['Accept'] = 'application/json';

axios.get('/sanctum/csrf-cookie');

window.axios = axios;

const app = createApp(App)
app.config.globalProperties.$permisos = permisos;
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$pusher = pusher;
app.config.globalProperties.$configuraciones = configuraciones;
app.config.globalProperties.$formatoNumero = (numero, formato = 'es-MX', decimals = 2) => {
    let numeroFormateado = 0;
    if (numero && numero !== '' && numero !== null && numero !== undefined && !isNaN(numero)) {
        numeroFormateado = Intl.NumberFormat('es-MX', {
            maximumFractionDigits: decimals,
        }).format(numero);
    }
    return numeroFormateado;
};
app.config.globalProperties.$textSubstring = (string, length = 40, showSuspensive = true) => {
    if (string) {
        if (string.length > length) {
            return string.substring(0, length) + (showSuspensive ? '...' : '')
        } else {
            return string
        }
    } else {
        return string;
    }
};

app.component('paginador', Paginador);
app.component('form-error', FormError);
app.component('flash-alert', FlashAlert);
app.component('dialogo-cargando', DialogoCargando);
app.component('text-counter', LabelTextCounter);

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
if (sentryDsn) {
    Sentry.init({
        app,
        dsn: sentryDsn,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],

        environment: import.meta.env.VITE_APP_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/kim-backend\.tresfactorial\.com\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.use(PrimeVue, {
    locale: PrimeVueLocaleEs
});
app.use(createPinia())
app.use(router)

app.mount('#app')

import Tooltip from 'primevue/tooltip';

app.directive('tooltip', Tooltip);

import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);
window.Chart = Chart;